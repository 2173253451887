import React, { useEffect, useRef } from 'react';
import * as css from './IntroQuestionsBlock.css';
import {
  PlatformSelector,
  PlatformSelector as PlatformSelectorType,
  Step,
} from '../../types';
import { useFirstSessionStepState } from '../common/useFirstSessionStepState';
import cn from 'classnames';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { FirstSessionBlockTitle } from '../common/FirstSessionBlockTitle';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useIntroQuestionsConfig } from './hooks/useIntroQuestionsConfig';
import { useIntroQuestionsData } from './hooks/useIntroQuestionsData';
import { sendEvent } from '@utils/Analytics';
import { useIntroQuestionsFormik } from './hooks/useIntroQuestionsFormik';
import { useIntroQuestionsPlatformSwitch } from './hooks/useIntroQuestionsPlatformSwitch';
import { IntroQuestionsConfigQuery_introQuestionsConfig_variables } from './hooks/@types/IntroQuestionsConfigQuery';
import { IntroQuestionsItem } from './components/IntroQuestionsItem';
import { HighlightBorderBoxHideable } from '@ui/Box/HighlightBorderBoxHideable';
import { CenteredLoader } from '@ui/Loader';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { useAdminData } from '@utils/Data/Admin';
import { OriginalAccountType } from '@globals';
import { initialWebAppParams } from '@utils/initialWebAppParams';

interface IntroQuestionsFormProp extends PlatformSelector {
  questionsConfig: IntroQuestionsConfigQuery_introQuestionsConfig_variables[];
  schemeId: string;
  stepDoneHandler: VoidFunction;
  stepSaving: boolean;
  isLastStep: boolean;
}

const IntroQuestionsForm: React.FC<IntroQuestionsFormProp> = ({
  questionsConfig,
  schemeId,
  platform,
  onPlatformChange,
  stepDoneHandler,
  stepSaving,
  isLastStep,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const { saveIntroQuestionsData, introQuestionsSavedData, saving, loading } =
    useIntroQuestionsData(schemeId);

  const { adminData } = useAdminData();
  const {
    values,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    errors,
    touched,
    validateField,
    handleSubmit,
  } = useIntroQuestionsFormik({
    platform,
    stepDoneHandler,
    saveIntroQuestionsData,
    questionsConfig,
    introQuestionsSavedData,
  });

  const saveRef = useRef(setFieldValue);
  saveRef.current = setFieldValue;

  useEffect(() => {
    if (loading) return;
    const initPlatform = initialWebAppParams.get('platform');
    if (initPlatform) saveRef.current('platform', [initPlatform]);
  }, [loading]);

  useIntroQuestionsPlatformSwitch({
    values,
    questionsConfig,
    platform,
    onPlatformChange,
  });

  return (
    <form
      onSubmit={handleSubmit}
      data-testid="first-session__intro-questions-block_form"
    >
      <Spacer factor={6} />
      {questionsConfig?.map((question, index) => (
        <IntroQuestionsItem
          key={question.id}
          item={question}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          validateField={validateField}
          handleBlur={handleBlur}
          platform={platform}
          index={index}
        />
      ))}
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        className={css.buttonBox}
      >
        {adminData?.me.original_account_type === OriginalAccountType.guest && (
          <Type size="15px">
            {t('pages.FirstSession.IntroQuestionsBlock.ByContinuing')}{' '}
            <Anchor
              intent="external"
              hideArrow
              href="https://chatfuel.com/files/TermsOfUse.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('pages.FirstSession.IntroQuestionsBlock.TOS')}
            </Anchor>
          </Type>
        )}
        <Button
          disabled={!isValid}
          className={cn(
            isSmallScreenSize && css.buttonMobile,
            isSmallScreenSize && css.buttonMobileByStep,
          )}
          type="submit"
          loading={saving || stepSaving}
          data-testid="first-session__intro-questions-block_done-button"
          onClick={() => {
            sendEvent({
              category: 'first-session',
              label: 'intro questions block',
              action: 'done button click',
              propertyBag: {
                platform,
              },
            });
          }}
        >
          {t(
            isLastStep
              ? 'pages.FirstSession.IntroQuestionsBlock.finish'
              : 'pages.FirstSession.IntroQuestionsBlock.NextStep',
          )}
        </Button>
      </Flex>
    </form>
  );
};

interface IntroQuestionsBlockProps extends Step, PlatformSelectorType {
  isLastStep: boolean;
}

export const IntroQuestionsBlock: React.FC<IntroQuestionsBlockProps> = ({
  onDone,
  done,
  enabled,
  current,
  stepSaving,
  platform,
  isLastStep,
  onPlatformChange,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const { questionsConfig, schemeId, loading } = useIntroQuestionsConfig();
  const { stepDoneHandler, scrollTargetRef, expand, stepBoxClickHandler } =
    useFirstSessionStepState({
      done,
      onDone,
      current,
      enabled,
    });

  if (loading) {
    return <CenteredLoader className={css.loader} />;
  }

  return (
    <HighlightBorderBoxHideable
      hide={isSmallScreenSize}
      ref={scrollTargetRef}
      highlightEnabled={false}
      borderRadiusFactor={3}
      className={cn(
        css.box,
        enabled && !expand && !isMobileDevice() && css.hover,
      )}
      innerClassName={css.boxInner}
      onClick={stepBoxClickHandler}
      data-testid="first-session__intro-questions-block"
    >
      <FirstSessionBlockTitle
        title={t('pages.FirstSession.IntroQuestionsBlock.title')}
        subtitle={t('pages.FirstSession.IntroQuestionsBlock.subtitle')}
        active={enabled}
      />
      {expand && (
        <IntroQuestionsForm
          platform={platform}
          schemeId={schemeId!}
          questionsConfig={questionsConfig!}
          stepDoneHandler={stepDoneHandler}
          stepSaving={stepSaving}
          isLastStep={isLastStep}
          onPlatformChange={onPlatformChange}
        />
      )}
    </HighlightBorderBoxHideable>
  );
};
